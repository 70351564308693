<script>
import capitalize from 'lodash-es/capitalize'
import concat from 'lodash-es/concat'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'
import unionBy from 'lodash-es/unionBy'
import uniqBy from 'lodash-es/uniqBy'
import upperFirst from 'lodash-es/upperFirst'
import { extend, ValidationObserver } from 'vee-validate'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { formatDate, createDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import Attachments from './components/attachments.vue'
import Comments from './components/comments.vue'
import Likes from './components/likes.vue'
import Poll from './components/poll.vue'
import Quiz from './components/quiz.vue'
import { useRecProfile } from '/~rec/composables/profile'
import { useLimits } from '/~rec/composables/limits'
import { useForm } from '/~/composables/base/use-form'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'

export default {
  name: 'rec-post-tokens-modal',
  components: {
    BaseDatepicker,
    Comments,
    Likes,
    Attachments,
    Quiz,
    Poll,
    HeaderMobile,
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseMdl,
    BaseLoader,
    ValidationObserver,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'post',
      validator: (v) => /post|poll|quiz/.test(v),
    },
    post: {
      type: Object,
      default: () => ({}),
    },
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { currency } = useRecProfile()
    const { actionEarnLimit } = useLimits()
    const { validationObserverRef } = useForm()

    return {
      currency,
      actionEarnLimit,
      ui,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {
        maxPoint: 100,
        endsAt: null,
      },
      hasEndsDate: false,
      earnConfigs: [],
      attachmentsWithConfig: [],
      loading: false,
      allAttachments: this.attachments,
      enableButton: false,
    }
  },
  computed: {
    modalTitle() {
      return this.title || `Add ${capitalize(this.currency)}`
    },
    typeString() {
      return upperFirst(this.type)
    },
    actions() {
      return this.actionEarnLimit.hitsByAction
    },
    isEmptyActions() {
      return isEmpty(this.actions)
    },
    allProps() {
      return {
        typeString: this.typeString || '',
        actionEarnLimits: this.actions || '',
        attachments: this.allAttachments || this.attachments,
        confirmedEarnConfigs: this.confirmedEarnConfigs || {},
        currency: this.currency || '',
      }
    },
    confirmedEarnConfigs() {
      const configs = {}
      const hits = get(this.post, 'earnConfigs')

      if (!hits) return

      for (let i = 0; i < hits.length; i++) {
        configs[hits[i].action] = hits[i]
      }

      return configs
    },
  },
  beforeMount() {
    extend('post_earn_config_required', {
      validate: (value) => {
        return ['', null, undefined].indexOf(value) === -1
          ? true
          : 'The field is required.'
      },
    })
  },
  async created() {
    this.loading = true
    await this.actionEarnLimit.getData()
    const maxEarnValue = get(this.post, 'maxEarnValue')
    const endsAt = get(this.post, 'earnConfigsEndsAt')

    if (maxEarnValue) {
      this.form.maxPoint = maxEarnValue
    }

    if (endsAt) {
      this.hasEndsDate = true
      this.form.endsAt = formatDate('daymonthyear', endsAt)
    }

    await this.getEarnConfigs()
    this.loading = false
  },
  methods: {
    createDate,
    close() {
      modal.hide()
    },
    async getEarnConfigs() {
      if (isEmpty(this.post)) return

      try {
        this.loading = true
        await this.post.fullPost()

        const attachments = this.post.attachments.hits
        const validAttachments = attachments.filter(
          (a) => a.type !== 'image/gif'
        )

        this.allAttachments = unionBy(validAttachments, this.attachments, 'id')
      } catch (error) {
        console.error('rec-room', error)
      } finally {
        this.loading = false
      }
    },
    setAttachmentConfig(data) {
      this.attachmentsWithConfig = data
    },
    async validateForm() {
      this.enableButton = await this.validationObserverRef.validate()
    },
    setConfig(data) {
      const mergedConfigs = concat(data, this.earnConfigs)
      const uniqConfigs = uniqBy(mergedConfigs, 'action')

      this.earnConfigs = uniqConfigs.map((item) => {
        return {
          ...item,
          ...{
            currency: this.currency,
            earn_strategy: 'instant',
          },
        }
      })
    },
    async onSave() {
      const earnConfigs = !isEmpty(this.earnConfigs)
        ? { earn_configs: this.earnConfigs }
        : {}
      const endsAt = this.hasEndsDate
        ? {
            earn_configs_ends_at:
              formatDate('daymonthyear', this.form.endsAt) + ' 00:00:00',
          }
        : {}
      const payload = {
        ...earnConfigs,
        ...endsAt,
        earn_config_limits: {
          points: this.form.maxPoint,
        },
      }

      this.$emit('save', payload, this.attachmentsWithConfig)
      this.close()
    },
  },
}
</script>

<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validationObserverRef"
    slim
  >
    <base-mdl
      :title="modalTitle"
      transition="slide-bottom"
      fullscreen="mobile"
      :width="ui.desktop ? 'sm' : 'screen'"
    >
      <template #header>
        <header-mobile v-if="ui.mobile" :title="modalTitle" @back="close" />
      </template>
      <div class="flex min-h-full flex-col">
        <div class="relative h-full pt-5 sm:pt-0">
          <base-loader v-if="loading" size="lg" class="my-5" fullwidth />
          <b class="text-xl leading-6 sm:text-base">
            Set rules for members to earn
            <span class="capitalize">
              {{ currency }}
            </span>
            on your Post
          </b>

          <div v-if="!loading && !isEmptyActions">
            <quiz
              v-if="type === 'quiz'"
              v-bind="allProps"
              @checkValidation="validateForm()"
              @update="setConfig"
            />
            <poll
              v-if="type === 'poll'"
              v-bind="allProps"
              @checkValidation="validateForm()"
              @update="setConfig"
            />
            <likes
              v-bind="allProps"
              @checkValidation="validateForm()"
              @update="setConfig"
            />
            <comments
              v-bind="allProps"
              @checkValidation="validateForm()"
              @update="setConfig"
            />
            <attachments
              v-if="attachments && attachments.length"
              v-bind="allProps"
              @checkValidation="validateForm()"
              @update="setAttachmentConfig"
            />
          </div>

          <div
            class="mt-5 flex flex-col sm:min-h-10 sm:flex-row sm:items-center"
          >
            <base-checkbox v-model="hasEndsDate" class="sm:mr-2.5">
              <div class="text-sm font-bold">
                Set end date for earning
                <span class="capitalize">
                  {{ currency }}
                </span>
              </div>
            </base-checkbox>
            <div
              v-if="hasEndsDate"
              class="ml-auto mt-2.5 w-full sm:mt-0 sm:max-w-48"
            >
              <base-datepicker
                v-model="form.endsAt"
                :validation="{
                  rules: 'post_earn_config_required',
                  name: 'Ends At',
                  mode: 'aggressive',
                }"
                name="endsAt"
                format="daymonthyear"
                nolabel
                :disabled-dates="{
                  to: createDate(),
                }"
                :min-date-mobile="new Date().toISOString().split('T')[0]"
              />
            </div>
          </div>
          <div
            class="mt-5 flex flex-col sm:mt-2.5 sm:min-h-10 sm:flex-row sm:items-center"
          >
            <div class="text-sm font-bold sm:mr-5">
              Set maximum number of
              <span class="capitalize">
                {{ currency }}
              </span>
            </div>
            <div class="ml-auto mt-2.5 w-full text-center sm:mt-0 sm:w-[120px]">
              <base-input
                v-model="form.maxPoint"
                :validation="{
                  rules: 'post_earn_config_required',
                  name: 'Max Points',
                }"
                :mask="{
                  mask: '0000000000',
                  lazy: true,
                }"
                name="maxPoint"
                nolabel
              />
            </div>
          </div>
        </div>
        <div class="mt-auto flex w-full pt-[30px]">
          <base-button
            look="light-filled"
            class="mr-[15px] flex-1"
            @click="close"
          >
            Cancel
          </base-button>
          <base-button
            class="ml-[15px] flex-1"
            :disabled="!enableButton"
            @click="handleSubmit(onSave)"
          >
            Save
          </base-button>
        </div>
      </div>
    </base-mdl>
  </validation-observer>
</template>
